import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const {
  default_related_movies,
  default_movies,
} = require("../const/default_xemphimplus_movies.json");
class Xemphimplus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      movie_image: null,
      movie_name: null,
      movie_description: null,
      isModalShown: false,
      shortlink: null,
      siteConfig: null,
    };
    this.textInput = React.createRef();
  }
  componentDidMount() {
    this.setState({
      siteConfig: this.props.siteConfig,
    });
    let currentLink = window.location.href;
    currentLink = currentLink.split("/");
    let shortlink = currentLink[currentLink.length - 1];
    this.setState({ shortlink });
  }
  render() {
    const movie_types = ["Phim bộ", "Phim lẻ", "Phim mới"];
    let { movies, related_movies } = this.props.siteConfig || {
      movies: default_movies,
      related_movies: default_related_movies,
    };
    const selectedMovie = movies[Math.floor(Math.random() * movies.length)];
    let isAllowed = this.props.isAllowed;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {selectedMovie
              ? selectedMovie.name
              : "Xem phim Ly Nhân Tâm Thượng Tập 01 Full [Vietsub + Thuyết minh]"}
          </title>
          <link rel="canonical" href="http://mysite.com/example" />
          <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#234556" />
          <meta name="msapplication-navbutton-color" content="#234556" />
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content="#234556"
          />
          <link
            rel="shortcut icon"
            href="https://xemphimplus.net/wp-content/uploads/2019/05/favicon.png"
            type="image/x-icon"
          />
          <meta
            name="description"
            content={
              selectedMovie
                ? selectedMovie.description
                : "Ly Nhân Tâm Thượng - The Sleepless Princess là câu chuyện kể về cô công chúa bị chứng bệnh lạ Từ Sơ Nguyệt . Cùng với anh chàng tướng quân mặt lạnh Tiết Diệu"
            }
          />
          <meta name="robots" content="index, follow" />
          <meta
            name="googlebot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <meta
            name="bingbot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <link
            rel="canonical"
            href="/xem-phim-ly-nhan-tam-thuong/tap-02-sv1.html"
          />
          <meta property="og:locale" content="vi_VN" />
          <meta property="og:type" content="video.movie" />
          <meta
            property="og:title"
            content={
              selectedMovie
                ? selectedMovie.name
                : "Xem phim Ly Nhân Tâm Thượng Tập 01 Full [Vietsub + Thuyết minh]"
            }
          />
          <meta
            property="og:description"
            content={
              selectedMovie
                ? selectedMovie.description
                : "Ly Nhân Tâm Thượng - The Sleepless Princess là câu chuyện kể về cô công chúa bị chứng bệnh lạ Từ Sơ Nguyệt . Cùng với anh chàng tướng quân mặt lạnh Tiết Diệu"
            }
          />
          <meta property="og:url" content="/ly-nhan-tam-thuong" />
          <meta
            property="og:site_name"
            content={
              selectedMovie
                ? selectedMovie.name
                : "Xem phim Ly Nhân Tâm Thượng Tập 01 Full [Vietsub + Thuyết minh]"
            }
          />
          <meta
            property="article:publisher"
            content="https://www.facebook.com/GaPhimPage/"
          />
          <meta
            property="article:published_time"
            content="2020-08-24T04:44:44-07:00"
          />
          <meta
            property="article:modified_time"
            content="2020-09-04T07:49:37-07:00"
          />
          <meta
            property="og:image"
            content={
              selectedMovie
                ? selectedMovie.image
                : "https://xemphimplus.net/wp-content/uploads/2020/08/ly-nhan-tam-thuong-1.jpg"
            }
          />
          <meta property="og:image:width" content={1280} />
          <meta property="og:image:height" content={720} />
          <link rel="dns-prefetch" href="https://s.w.org/" />
          <meta
            name="description"
            content={
              selectedMovie
                ? selectedMovie.description
                : "Ly Nhân Tâm Thượng - The Sleepless Princess là câu chuyện kể về cô công chúa bị chứng bệnh lạ Từ Sơ Nguyệt . Cùng với anh chàng tướng quân mặt lạnh Tiết Diệu"
            }
          />
          <link
            rel="stylesheet"
            id="bootstrap-css"
            href="https://xemphimplus.net/wp-content/themes/halimmovies/assets/css/bootstrap.min.css?ver=5.3.4"
            media="all"
          />
          <link
            rel="stylesheet"
            id="halimmovies-style-css"
            href="https://xemphimplus.net/wp-content/themes/halimmovies-child/style.css?ver=5.4.2"
            media="all"
          />
          <link
            rel="icon"
            href="https://xemphimplus.net/wp-content/uploads/2019/05/favicon.png"
            sizes="32x32"
          />
          <link
            rel="icon"
            href="https://xemphimplus.net/wp-content/uploads/2019/05/favicon.png"
            sizes="192x192"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="https://xemphimplus.net/wp-content/uploads/2019/05/favicon.png"
          />
          <meta
            name="msapplication-TileImage"
            content="/wp-content/uploads/2019/05/favicon.png"
          />
        </Helmet>
        <div>
          <div className="navbar-container">
            <div className="container">
              <nav
                className="navbar halim-navbar main-navigation"
                role="navigation"
                data-dropdown-hover={1}
              >
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle collapsed pull-left"
                    data-toggle="collapse"
                    aria-expanded="false"
                  >
                    <span className="sr-only">Menu</span>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                <div className="collapse navbar-collapse" id="halim">
                  <div className="menu-main-menu-container">
                    <ul
                      id="menu-main-menu"
                      className="nav navbar-nav navbar-left"
                    >
                      {movie_types.map((type) => {
                        return (
                          <li>
                            <a title={type} href="#">
                              {type}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>{" "}
                </div>
              </nav>
            </div>
          </div>
          <div className="text-center" />
          <div className="container">
            <div className="row fullwith-slider"></div>
          </div>
          <div className="container">
            <div className="row container" id="wrapper">
              <main id="main-contents" className="col-xs-12 col-sm-12 col-md-8">
                <section id="content">
                  <div className="clearfix wrap-content">
                    <div className="clearfix" />
                    <div className="text-center">
                      {" "}
                      <div className="textwidget"></div>
                    </div>{" "}
                    <div className="clearfix" />
                    <div
                      id="halim-player-wrapper"
                      className="ajax-player-loading"
                      data-adult-content
                    >
                      <div id="halim-player-loader" style={{ display: "none" }}>
                        <p style={{ marginTop: "15px" }}>
                          Đang khởi tạo trình phát, vui lòng chờ...
                        </p>
                      </div>
                      <div id="ajax-player" className="player">
                        <div className="embed-responsive embed-responsive-16by9">
                          {isAllowed ? (
                            <React.Fragment>
                              <img
                                itemProp="image"
                                src={selectedMovie.image}
                                width="945"
                                height="530"
                                alt={selectedMovie.name}
                              />
                              <Link to={`/${this.state.shortlink}/login`}>
                                <span
                                  className="icon-play-backdrop"
                                  style={{
                                    textIndent: "-1000em",
                                    display: "block",
                                    width: "63px",
                                    height: "63px",
                                    background:
                                      "url(http://t.hdviet.com/v2/css/images/btn_play_player.png) center center no-repeat",
                                    margin: "0 auto",
                                    position: "absolute",
                                    top: "45%",
                                    left: 0,
                                    right: 0,
                                  }}
                                  // onClick={this.handleClickPlayButton}
                                >
                                  Play
                                </span>
                              </Link>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <img
                                itemProp="image"
                                src={selectedMovie.image}
                                width="945"
                                height="530"
                                alt={selectedMovie.name}
                              />
                              <span
                                className="icon-play-backdrop"
                                style={{
                                  textIndent: "-1000em",
                                  display: "block",
                                  width: "63px",
                                  height: "63px",
                                  background:
                                    "url(http://t.hdviet.com/v2/css/images/btn_play_player.png) center center no-repeat",
                                  margin: "0 auto",
                                  position: "absolute",
                                  top: "45%",
                                  left: 0,
                                  right: 0,
                                }}
                              >
                                Play
                              </span>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="clearfix" />
                    <div className="text-center">
                      <div className="textwidget">
                        <p
                          style={{
                            color: "yellow",
                            border: "1px solid",
                            padding: "5px",
                          }}
                        >
                          Khi vào xem phim vui lòng đợi 15-30s để được xem phim
                          mượt nhất!
                        </p>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="title-block watch-page">
                      <a
                        href="javascript:;"
                        data-toggle="tooltip"
                        title
                        data-original-title="Thêm vào tủ phim"
                      >
                        <div
                          id="bookmark"
                          className="bookmark-img-animation primary_ribbon"
                          data-post_id={25690}
                          data-thumbnail={movies.image}
                          data-href="https://xemphimplus.net/ly-nhan-tam-thuong"
                          data-title={movies.name}
                          data-date="2020-08-24"
                        ></div>
                      </a>
                      <div className="title-wrapper full">
                        <h1 className="entry-title">
                          <a
                            href="https://xemphimplus.net/ly-nhan-tam-thuong"
                            title={
                              selectedMovie
                                ? selectedMovie.name
                                : "Xem phim Ly Nhân Tâm Thượng Tập 01 Full [Vietsub + Thuyết minh]"
                            }
                            className="tl"
                          >
                            {selectedMovie
                              ? selectedMovie.name
                              : "Xem phim Ly Nhân Tâm Thượng Tập 01 Full [Vietsub + Thuyết minh]"}
                          </a>
                        </h1>
                        <span
                          className="plot-collapse"
                          data-toggle="collapse"
                          data-target="#expand-post-content"
                          aria-expanded="false"
                          aria-controls="expand-post-content"
                          data-text="Nội dung phim"
                        >
                          <i className="hl-angle-down" />
                        </span>
                      </div>
                      <div className="ratings_wrapper">
                        <div className="halim_imdbrating taq-score">
                          <span className="score">3.8</span>
                          <i>/</i>
                          <span className="max-ratings">5</span>
                          <span className="total_votes">15</span>
                          <span className="vote-txt"> đánh giá</span>
                        </div>
                        <div className="rate-this">
                          <div
                            data-rate="75.933333333333"
                            data-id={25690}
                            className="user-rate user-rate-active"
                          >
                            <span className="user-rate-image post-large-rate stars-large">
                              <span style={{ width: "75.9333%" }} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="entry-content htmlwrap clearfix"
                      id="expand-post-content"
                      style={{ color: "#a5a5a5" }}
                    >
                      <article
                        id="post-25690"
                        className="item-content post-25690"
                      >
                        <p>
                          {selectedMovie
                            ? selectedMovie.description
                            : "Ly Nhân Tâm Thượng - The Sleepless Princess là câu chuyện kể về cô công chúa bị chứng bệnh lạ Từ Sơ Nguyệt . Cùng với anh chàng tướng quân mặt lạnh Tiết Diệu"}
                        </p>
                      </article>
                    </div>
                    <div className="clearfix" />
                    <div className="text-center halim-ajax-list-server">
                      <div id="halim-ajax-list-server" />
                    </div>
                    <div id="halim-list-server" className="list-eps-ajax">
                      <div
                        className="halim-server show_all_eps"
                        data-episode-nav
                      >
                        <span className="halim-server-name">
                          <span className="hl-server" /> Server #1
                        </span>
                        <ul id="listsv-1" className="halim-list-eps">
                          {isAllowed ? (
                            related_movies.map((e, index) => {
                              return (
                                <li
                                  className={`halim-episode halim-episode-${
                                    index + 1
                                  }-tap-0${index + 1}`}
                                >
                                  <a href="#" title={`Tập ${index + 1}`}>
                                    <span
                                      className="halim-info-1-tap-01 box-shadow halim-btn"
                                      data-post-id={25690}
                                      data-server={1}
                                      data-episode-slug="tap-01"
                                      data-position="first"
                                      data-embed={0}
                                    >
                                      Tập {index + 1}
                                    </span>
                                  </a>
                                </li>
                              );
                            })
                          ) : (
                            <React.Fragment>
                              <li
                                className={`halim-episode halim-episode-${1}-tap-0${1}`}
                              >
                                <a href="#" title={`Tập ${1}`}>
                                  <span
                                    className="halim-info-1-tap-01 box-shadow halim-btn"
                                    data-post-id={25690}
                                    data-server={1}
                                    data-episode-slug="tap-01"
                                    data-position="first"
                                    data-embed={0}
                                  >
                                    Tập 1
                                  </span>
                                </a>
                              </li>
                              <li
                                className={`halim-episode halim-episode-${1}-tap-0${1}`}
                              >
                                <a href="#" title={`Tập ${2}`}>
                                  <span
                                    className="halim-info-1-tap-01 box-shadow halim-btn"
                                    data-post-id={25690}
                                    data-server={1}
                                    data-episode-slug="tap-02"
                                    data-position="first"
                                    data-embed={0}
                                  >
                                    Tập 2
                                  </span>
                                </a>
                              </li>
                              <li
                                className={`halim-episode halim-episode-${3}-tap-0${3}`}
                              >
                                <a href="#" title={`Tập ${3}`}>
                                  <span
                                    className="halim-info-1-tap-01 box-shadow halim-btn"
                                    data-post-id={25690}
                                    data-server={1}
                                    data-episode-slug="tap-03"
                                    data-position="first"
                                    data-embed={0}
                                  >
                                    Tập 3
                                  </span>
                                </a>
                              </li>
                            </React.Fragment>
                          )}
                        </ul>
                        <div className="clearfix" />
                      </div>
                      <div id="pagination-1" />
                    </div>{" "}
                    <div className="clearfix" />
                    <div id="lightout" />
                  </div>
                </section>
                <section className="related-movies">
                  <div id="halim_related_movies-2xx" className="wrap-slider">
                    <div className="section-bar clearfix">
                      <h3 className="section-title">
                        <span>CÓ THỂ BẠN MUỐN XEM?</span>
                      </h3>
                    </div>
                    <div
                      id="halim_related_movies-2"
                      className="owl-carousel owl-theme related-film owl-loaded owl-drag"
                    >
                      <div className="owl-stage-outer">
                        <div
                          className="owl-stage"
                          style={{
                            transform: "translate3d(-1221px, 0px, 0px)",
                            transition: "all 0.25s ease 0s",
                            width: "3256px",
                          }}
                        >
                          <div
                            className="owl-item cloned"
                            style={{ width: "199.5px", marginRight: "4px" }}
                          ></div>
                          <div
                            className="owl-item cloned"
                            style={{ width: "199.5px", marginRight: "4px" }}
                          ></div>
                          <div
                            className="owl-item active"
                            style={{ width: "199.5px", marginRight: "4px" }}
                          ></div>
                          <div
                            className="owl-item active"
                            style={{ width: "199.5px", marginRight: "4px" }}
                          ></div>
                          <div
                            className="owl-item"
                            style={{ width: "199.5px", marginRight: "4px" }}
                          ></div>
                          <div
                            className="owl-item"
                            style={{ width: "199.5px", marginRight: "4px" }}
                          ></div>
                          {related_movies.map((movie) => {
                            return (
                              <div
                                className="owl-item cloned"
                                style={{
                                  width: "199.5px",
                                  marginRight: "4px",
                                }}
                              >
                                <article className="thumb grid-item post-11124">
                                  <div className="halim-item">
                                    <a
                                      className="halim-thumb"
                                      href="/bi-mat-cua-to"
                                      title={movie.name}
                                    >
                                      <figure>
                                        <img
                                          className="blur-up img-responsive lazyautosizes lazyloaded"
                                          data-sizes="auto"
                                          data-src="https://xemphimplus.net//wp-content/uploads/2019/10/bi-mat-cua-to-11124-thumbnail.jpg"
                                          alt={movie.name}
                                          title={movie.name}
                                          sizes="198px"
                                          src={movie.image}
                                        />
                                      </figure>
                                      <span className="status">HD</span>
                                      <span className="episode">Tập 10</span>
                                      <div className="halim-post-title-box">
                                        <div className="halim-post-title ">
                                          <h2 className="entry-title">
                                            {movie.name}
                                          </h2>
                                          <p className="original_title">
                                            {movie.name}
                                          </p>{" "}
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </article>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
              <aside id="sidebar" className="col-xs-12 col-sm-12 col-md-4">
                <div
                  id="halim_tab_popular_videos-widget-5"
                  className="widget halim_tab_popular_videos-widget"
                >
                  <div className="section-bar clearfix">
                    <div className="section-title">
                      <span>Nổi bật</span>
                      <ul className="halim-popular-tab" role="tablist">
                        <li role="presentation" className="active">
                          <a
                            className="ajax-tab"
                            role="tab"
                            data-toggle="tab"
                            data-showpost={10}
                            data-type="day"
                          >
                            Ngày
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            className="ajax-tab"
                            role="tab"
                            data-toggle="tab"
                            data-showpost={10}
                            data-type="week"
                          >
                            Tuần
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            className="ajax-tab"
                            role="tab"
                            data-toggle="tab"
                            data-showpost={10}
                            data-type="month"
                          >
                            Tháng
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            className="ajax-tab"
                            role="tab"
                            data-toggle="tab"
                            data-showpost={10}
                            data-type="all"
                          >
                            Tất cả
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <section className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane active halim-ajax-popular-post"
                    >
                      <div className="halim-ajax-popular-post-loading hidden" />
                      <div
                        id="halim-ajax-popular-post"
                        className="popular-post"
                      >
                        {isAllowed ? (
                          related_movies.map((movie) => {
                            return (
                              <div className="item post-28067">
                                <a
                                  href="/quy-ong-hoan-hao-va-co-nang-tam-duoc"
                                  title={movie.name}
                                >
                                  <div className="item-link">
                                    <img
                                      src={movie.image}
                                      data-src={movie.image}
                                      className="blur-up post-thumb lazyloaded"
                                      alt={movie.name}
                                      title={movie.name}
                                    />
                                  </div>
                                  <h3 className="title">{movie.name}</h3>
                                  <p className="original_title">
                                    {movie.name}
                                  </p>{" "}
                                </a>
                                <div className="viewsCount">7.7K lượt xem</div>
                              </div>
                            );
                          })
                        ) : (
                          <React.Fragment>
                            <div className="item post-28611">
                              <a
                                href="/audrie-va-daisy"
                                title="Audrie Và Daisy"
                              >
                                <div className="item-link">
                                  <img
                                    src="https://xemphimplus.net/wp-content/uploads/2020/10/audrie-va-daisy-audrie-daisy-81647-300x450.jpg"
                                    data-src="/wp-content/uploads/2020/10/audrie-va-daisy-audrie-daisy-81647-300x450.jpg"
                                    className="blur-up post-thumb lazyloaded"
                                    alt="Audrie Và Daisy"
                                    title="Audrie Và Daisy"
                                  />
                                </div>
                                <h3 className="title">Audrie Và Daisy</h3>
                                <p className="original_title">
                                  Audrie Và Daisy
                                </p>{" "}
                              </a>
                              <div className="viewsCount">1.2K lượt xem</div>
                            </div>
                            <div className="item post-28411">
                              <a
                                href="/thoi-khac-phi-thuong"
                                title="Thời Khắc Phi Thường"
                              >
                                <div className="item-link">
                                  <img
                                    src="https://xemphimplus.net/wp-content/uploads/2020/10/Thời_khắc_phi_thường_2016_poster-300x450.jpg"
                                    data-src="/wp-content/uploads/2020/10/Thời_khắc_phi_thường_2016_poster-300x450.jpg"
                                    className="blur-up post-thumb lazyloaded"
                                    alt="Thời Khắc Phi Thường"
                                    title="Thời Khắc Phi Thường"
                                  />
                                </div>
                                <h3 className="title">Thời Khắc Phi Thường</h3>
                                <p className="original_title">
                                  The Finest Hours{" "}
                                </p>{" "}
                              </a>
                              <div className="viewsCount">1.2K lượt xem</div>
                            </div>
                            <div className="item post-28286">
                              <a href="/nguoi-me-robott" title="Người Mẹ Robot">
                                <div className="item-link">
                                  <img
                                    src="https://xemphimplus.net/wp-content/uploads/2020/10/mv5bmtkxmtcznta4nl5bml5banbnxkftztgwndaymzgwodm-_v1_-300x450.jpg"
                                    data-src="/wp-content/uploads/2020/10/mv5bmtkxmtcznta4nl5bml5banbnxkftztgwndaymzgwodm-_v1_-300x450.jpg"
                                    className="blur-up post-thumb lazyloaded"
                                    alt="Người Mẹ Robot"
                                    title="Người Mẹ Robot"
                                  />
                                </div>
                                <h3 className="title">Người Mẹ Robot</h3>
                                <p className="original_title">
                                  I Am Mother (2019)
                                </p>{" "}
                              </a>
                              <div className="viewsCount">1.2K lượt xem</div>
                            </div>
                            <div className="item post-28469">
                              <a
                                href="/truyen-ky-dich-nhan-kiet-luan-hoi-do"
                                title="Truyền Kỳ Địch Nhân Kiệt: Luân Hồi Đồ"
                              >
                                <div className="item-link">
                                  <img
                                    src="https://xemphimplus.net/wp-content/uploads/2020/10/460_Dichnhankiet-300x450.jpg"
                                    data-src="/wp-content/uploads/2020/10/460_Dichnhankiet-300x450.jpg"
                                    className="blur-up post-thumb lazyloaded"
                                    alt="Truyền Kỳ Địch Nhân Kiệt: Luân Hồi Đồ"
                                    title="Truyền Kỳ Địch Nhân Kiệt: Luân Hồi Đồ"
                                  />
                                </div>
                                <h3 className="title">
                                  Truyền Kỳ Địch Nhân Kiệt: Luân Hồi Đồ
                                </h3>
                                <p className="original_title">
                                  The Story Of Renjie Di's Rebirth Picture
                                </p>{" "}
                              </a>
                              <div className="viewsCount">1.2K lượt xem</div>
                            </div>
                            <div className="item post-28133">
                              <a href="/ky-sinh-thu-2" title="Ký Sinh Thú 2">
                                <div className="item-link">
                                  <img
                                    src="https://xemphimplus.net/wp-content/uploads/2020/10/Parasyte-Poster-300x450.jpg"
                                    data-src="/wp-content/uploads/2020/10/Parasyte-Poster-300x450.jpg"
                                    className="blur-up post-thumb lazyloaded"
                                    alt="Ký Sinh Thú 2"
                                    title="Ký Sinh Thú 2"
                                  />
                                </div>
                                <h3 className="title">Ký Sinh Thú 2</h3>
                                <p className="original_title">
                                  {" "}
                                  Parasyte Part 2
                                </p>{" "}
                              </a>
                              <div className="viewsCount">1.2K lượt xem</div>
                            </div>
                            <div className="item post-28689">
                              <a
                                href="/chi-rieng-minh-em-always"
                                title="Chỉ Riêng Mình Em - Always"
                              >
                                <div className="item-link">
                                  <img
                                    src="https://xemphimplus.net/wp-content/uploads/2020/10/MV5BMTc4NjcwNDIwNl5BMl5BanBnXkFtZTcwNTA0NTg2Nw@@.UX400-300x450.jpg"
                                    data-src="/wp-content/uploads/2020/10/MV5BMTc4NjcwNDIwNl5BMl5BanBnXkFtZTcwNTA0NTg2Nw@@.UX400-300x450.jpg"
                                    className="blur-up post-thumb lazyloaded"
                                    alt="Chỉ Riêng Mình Em - Always"
                                    title="Chỉ Riêng Mình Em - Always"
                                  />
                                </div>
                                <h3 className="title">
                                  Chỉ Riêng Mình Em - Always
                                </h3>
                                <p className="original_title">Always</p>{" "}
                              </a>
                              <div className="viewsCount">1.2K lượt xem</div>
                            </div>
                            <div className="item post-27949">
                              <a
                                href="/hay-de-tuyet-roi-2"
                                title="Hãy Để Tuyết Rơi"
                              >
                                <div className="item-link">
                                  <img
                                    src="https://xemphimplus.net/wp-content/uploads/2020/10/MV5BYmM0NzRiODEtMTIzNS00MTgwLWFjZDQtNjUxZjI0MDdjZWQ4XkEyXkFqcGdeQXVyMTMxODk2OTU@.UX400-300x450.jpg"
                                    data-src="/wp-content/uploads/2020/10/MV5BYmM0NzRiODEtMTIzNS00MTgwLWFjZDQtNjUxZjI0MDdjZWQ4XkEyXkFqcGdeQXVyMTMxODk2OTU@.UX400-300x450.jpg"
                                    className="blur-up post-thumb lazyloaded"
                                    alt="Hãy Để Tuyết Rơi"
                                    title="Hãy Để Tuyết Rơi"
                                  />
                                </div>
                                <h3 className="title">Hãy Để Tuyết Rơi</h3>
                                <p className="original_title">
                                  Let It Snow (2019)
                                </p>{" "}
                              </a>
                              <div className="viewsCount">1.2K lượt xem</div>
                            </div>
                            <div className="item post-28232">
                              <a
                                href="/dai-thoai-tay-du-2-tien-ly-ky-duyen"
                                title="Đại Thoại Tây Du 2: Tiên Lý Kỳ Duyên"
                              >
                                <div className="item-link">
                                  <img
                                    src="https://xemphimplus.net/wp-content/uploads/2020/10/unnamed-6.jpg"
                                    data-src="/wp-content/uploads/2020/10/unnamed-6.jpg"
                                    className="blur-up post-thumb lazyloaded"
                                    alt="Đại Thoại Tây Du 2: Tiên Lý Kỳ Duyên"
                                    title="Đại Thoại Tây Du 2: Tiên Lý Kỳ Duyên"
                                  />
                                </div>
                                <h3 className="title">
                                  Đại Thoại Tây Du 2: Tiên Lý Kỳ Duyên
                                </h3>
                                <p className="original_title">
                                  A Chinese Odyssey: Part Two - Cinderella
                                </p>{" "}
                              </a>
                              <div className="viewsCount">1.2K lượt xem</div>
                            </div>
                            <div className="item post-28067">
                              <a
                                href="/quy-ong-hoan-hao-va-co-nang-tam-duoc"
                                title="Quý Ông Hoàn Hảo Và Cô Nàng Tạm Được"
                              >
                                <div className="item-link">
                                  <img
                                    src="https://xemphimplus.net/wp-content/uploads/2020/10/321654654321-300x450.png"
                                    data-src="/wp-content/uploads/2020/10/321654654321-300x450.png"
                                    className="blur-up post-thumb lazyloaded"
                                    alt="Quý Ông Hoàn Hảo Và Cô Nàng Tạm Được"
                                    title="Quý Ông Hoàn Hảo Và Cô Nàng Tạm Được"
                                  />
                                </div>
                                <h3 className="title">
                                  Quý Ông Hoàn Hảo Và Cô Nàng Tạm Được
                                </h3>
                                <p className="original_title">
                                  Perfect And Casual
                                </p>{" "}
                              </a>
                              <div className="viewsCount">1.2K lượt xem</div>
                            </div>
                            <div className="item post-28724">
                              <a
                                href="/cam-on-vi-da-de-anh-gap-duoc-em"
                                title="Cảm Ơn Vì Đã Để Anh Gặp Được Em"
                              >
                                <div className="item-link">
                                  <img
                                    src="https://xemphimplus.net/wp-content/uploads/2020/10/993c95ca66f9393becd1da599d5a01b0.jpg"
                                    data-src="/wp-content/uploads/2020/10/993c95ca66f9393becd1da599d5a01b0.jpg"
                                    className="blur-up post-thumb lazyloaded"
                                    alt="Cảm Ơn Vì Đã Để Anh Gặp Được Em"
                                    title="Cảm Ơn Vì Đã Để Anh Gặp Được Em"
                                  />
                                </div>
                                <h3 className="title">
                                  Cảm Ơn Vì Đã Để Anh Gặp Được Em
                                </h3>
                                <p className="original_title">Meeting You</p>{" "}
                              </a>
                              <div className="viewsCount">1.2K lượt xem</div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </section>
                  <div className="clearfix" />
                </div>
              </aside>
            </div>
          </div>
        </div>
        {/*<LoginModal*/}
        {/*  ref={(child) => {*/}
        {/*    this._childLogin = child;*/}
        {/*  }}*/}
        {/*  // onReload={(shouldReload) => this.reloadPixel(shouldReload)}*/}
        {/*/>*/}
      </>
    );
  }
}
export default Xemphimplus;
