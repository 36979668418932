const ipRangeCheck = require("ip-range-check");
function checkAllow(config, geo, ua, current_ip) {
  let is_allowed = true;
  if (ua.isBot) return false;
  let country = geo.country_code;
  for (let i = 0; i < config.length; i++) {
    if (config[i].type.value === "country") {
      let country_value = [];
      config[i].value.map((ct) => {
        country_value.push(ct.value);
      });
      if (config[i].operator.value === "in") {
        if (country_value.includes(country)) {
          is_allowed = true;
        } else {
          console.log("country IN fail");
          is_allowed = false;
          return is_allowed;
        }
      } else if (config[i].operator.value === "not_in") {
        if (country_value.includes(country)) {
          console.log("country NOT_IN fail");
          is_allowed = false;
          return is_allowed;
        } else {
          is_allowed = true;
        }
      }
    } else if (config[i].type.value === "browser") {
      let browser_value = [];
      config[i].value.map((b) => {
        browser_value.push(b.value);
      });
      let currentBrowser = "";
      if (ua.isChrome) currentBrowser = "chrome";
      if (ua.isFirefox) currentBrowser = "firefox";
      if (ua.isOpera) currentBrowser = "opera";
      if (ua.isEdge) currentBrowser = "edge";
      if (ua.isSafari) currentBrowser = "safari";
      if (ua.isUC) currentBrowser = "uc";
      if (config[i].operator.value === "in") {
        if (!browser_value.includes(currentBrowser)) {
          console.log("Browser IN fail");
          is_allowed = false;
          return is_allowed;
        }
      } else if (config[i].operator.value === "not_in") {
        if (browser_value.includes(currentBrowser)) {
          console.log("Browser NOT_IN fail");
          is_allowed = false;
          return is_allowed;
        }
      }
    } else if (config[i].type.value === "os") {
      let os_value = [];
      config[i].value.map((b) => {
        os_value.push(b.value);
      });
      let currentOs = "";
      if (ua.isWindows) currentOs = "windows";
      if (ua.isMac) currentOs = "macos";
      if (ua.isLinux) currentOs = "linux";
      if (ua.isAndroid) currentOs = "android";
      if (ua.isiPhone) currentOs = "osx";
      if (ua.isiPad) currentOs = "osx";
      if (ua.isiPod) currentOs = "osx";

      if (config[i].operator.value === "in") {
        if (!os_value.includes(currentOs)) {
          console.log("OS IN fail");
          is_allowed = false;
          return is_allowed;
        }
      } else if (config[i].operator.value === "not_in") {
        if (os_value.includes(currentOs)) {
          console.log("OS NOT_IN fail");
          is_allowed = false;
          return is_allowed;
        }
      }
    } else if (config[i].type.value === "device") {
      let device_value = [];
      config[i].value.map((b) => {
        device_value.push(b.value);
      });
      let currentDevice = "";
      if (ua.isDesktop) currentDevice = "desktop";
      if (ua.isTablet) currentDevice = "tablet";
      if (ua.isMobile) currentDevice = "mobile";
      if (config[i].operator.value === "in") {
        if (!device_value.includes(currentDevice)) {
          console.log("Device IN fail");
          is_allowed = false;
          return is_allowed;
        }
      } else if (config[i].operator.value === "not_in") {
        if (device_value.includes(currentDevice)) {
          console.log("Device NOT_IN fail");
          is_allowed = false;
          return is_allowed;
        }
      }
    } else if (config[i].type.value === "ip") {
      let ipArray = config[i].value.split("\n");
      let isInRage = ipRangeCheck(current_ip, ipArray);
      if (config[i].operator.value === "blacklist") {
        if (isInRage) {
          console.log("IP blacklist fail");
          is_allowed = false;
          return is_allowed;
        }
      } else if (config[i].operator.value === "whitelist") {
        if (!isInRage) {
          console.log("IP whitelist fail");
          is_allowed = false;
          return is_allowed;
        }
      }
    }
  }
  return is_allowed;
}

module.exports = {
  checkAllow,
};
