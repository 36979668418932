const { server_url } = require("../../const/const.json");
const apiSender = require("../api-sender");
const ipLogging = (data) => {
  let route = `${server_url}/api/webapp/iplogging?query=${data}`;
  return apiSender
    .get(route)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log(err);
      throw new Error(err.message);
    });
};
module.exports = {
  ipLogging,
};
