"use strict";

const request = require("request");
const rp = require("request-promise");
const fs = require("fs");
function postJSON(url, payload) {
  return new Promise((resolve, reject) => {
    request(
      {
        url: url,
        method: "POST",
        json: true,
        body: payload,
      },
      function (err, response, body) {
        if (err) return reject(err);

        try {
          resolve(JSON.parse(body));
        } catch (err) {
          resolve(body);
        }
      }
    );
  });
}

function postJSONBulk(url, body) {
  const options = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  };
  return new Promise((resolve, reject) => {
    request(options, function (err, response, body) {
      if (err) return reject(err);
      try {
        resolve(JSON.parse(body));
      } catch (err) {
        resolve(body);
      }
    });
  });
}

function postBulk(url, payload) {
  return postJSONBulk(url, payload).then((data) => {
    if (data.error) {
      return Promise.reject(data.error.message);
    }
    return Promise.resolve(data);
  });
}

function postFb(url, payload) {
  return postJSON(url, payload).then((data) => {
    if (data.error) {
      return Promise.reject(data.error.message);
    }
    return Promise.resolve(data);
  });
}

function get(url) {
  return new Promise((resolve, reject) => {
    request(
      {
        url: url,
        method: "GET",
      },
      function (err, response, body) {
        if (err) return reject(err);

        try {
          resolve(JSON.parse(body));
        } catch (err) {
          resolve(body);
        }
      }
    );
  });
}

function getFb(url) {
  return get(url).then((data) => {
    return Promise.resolve(data);
  });
}

function post(url) {
  return new Promise((resolve, reject) => {
    request(
      {
        url: url,
        method: "POST",
      },
      (err, response, body) => {
        if (err) return reject(err);
        resolve(JSON.parse(body));
      }
    );
  });
}

function deleteFb(url) {
  return new Promise((resolve, reject) => {
    request(
      {
        url: url,
        method: "DELETE",
      },
      (err, response, body) => {
        if (err) return reject(err);
        resolve(JSON.parse(body));
      }
    );
  });
}

function deleteJSON(url, payload) {
  return new Promise((resolve, reject) => {
    request(
      {
        url: url,
        method: "DELETE",
        json: true,
        body: payload,
      },
      function (err, response, body) {
        if (err) return reject(err);

        try {
          resolve(JSON.parse(body));
        } catch (err) {
          resolve(body);
        }
      }
    );
  });
}

function callSendMessengerAPI(payload, pageToken) {
  let options = {
    uri: "https://graph.facebook.com/v2.6/me/messages",
    qs: {
      access_token: pageToken,
    },
    method: "POST",
    body: payload,
    json: true,
  };

  return rp(options);
}

function getUidPhoneServer(url, secret) {
  let headers = {
    authorization: secret,
  };
  return new Promise((resolve, reject) => {
    request(
      {
        url: url,
        method: "GET",
        headers: headers,
      },
      function (err, response, body) {
        if (err) return reject(err);

        try {
          resolve(JSON.parse(body));
        } catch (err) {
          resolve(body);
        }
      }
    );
  });
}

function loginTiki() {
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  return new Promise((resolve, reject) => {
    request(
      {
        url: "https://sellercenter.tiki.vn/api/token/request",
        method: "POST",
        headers: headers,
        formData: {
          email: "nguyenhopquang1999@gmail.com",
          password: "Quang@13031999",
        },
      },
      function (err, response, body) {
        if (err) return reject(err);

        try {
          resolve(JSON.parse(body));
        } catch (err) {
          resolve(body);
        }
      }
    );
  });
}
function uploadImageToTiki(imagePath, token) {
  let headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + token,
  };
  let formdata = fs.createReadStream(imagePath);
  console.log(formdata);
  return new Promise((resolve, reject) => {
    request(
      {
        url: "https://sellercenter.tiki.vn/api/token/request",
        method: "POST",
        headers: headers,
        formData: {
          files: formdata,
        },
      },
      function (err, response, body) {
        if (err) return reject(err);
        try {
          resolve(JSON.parse(body));
        } catch (err) {
          resolve(body);
        }
      }
    );
  });
}
module.exports = {
  postJSON: postJSON,
  loginTiki,
  uploadImageToTiki,
  get: get,
  getFb: getFb,
  postFb: postFb,
  post: post,
  postBulk: postBulk,
  postJSONBulk: postJSONBulk,
  deleteFb: deleteFb,
  deleteJSON: deleteJSON,
  callSendMessengerAPI: callSendMessengerAPI,
  getUidPhoneServer: getUidPhoneServer,
};
