import React from "react";
import Login1 from "./Login/Login1";
import { getIpData } from "../services/ip/ip";
const useragent = require("express-useragent");

class RLogin extends React.Component {
  constructor(props) {
    super(props);
    // Create a ref to store the textInput DOM element
    this.textInput = React.createRef();
    this.state = {
      ipInfo: null,
      userAgent: null,
    };
  }
  async componentDidMount() {
    let ipInfo = await getIpData();
    let userAgent = useragent.parse(navigator.userAgent);
    await this.setState({ ipInfo: ipInfo, userAgent: userAgent });
  }

  render() {
    let isAllowed = localStorage.getItem("isAllowed");
    if (isAllowed === "false") {
      this.props.history.push("/phim");
    }
    if (this.state.ipInfo && this.state.userAgent) {
      return (
        <Login1 ipInfo={this.state.ipInfo} userAgent={this.state.userAgent} />
      );
    } else {
      return <></>;
    }
  }
}
export default RLogin;
