const apiSender = require("../api-sender");
const getIpData = () => {
  let route = `https://geoip-db.com/json/`;
  return apiSender
    .get(route)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log(err);
      throw new Error(err.message);
    });
};
module.exports = {
  getIpData,
};
