const { server_url } = require("../../const/const.json");
const apiSender = require("../api-sender");
const getCampaignInfo = (url, shortlink) => {
  let route = `${server_url}/api/webapp/scampaign?domain=${url}&shortlink=${shortlink}`;
  return apiSender
    .get(route)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
};
module.exports = {
  getCampaignInfo,
};
