import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ThankYou from "./routes/ThankYou";
import RLogin from "./routes/RLogin";
import Movie from "./routes/Movie";
const App = () => (
  <Router>
    <Switch>
      <Route exact path="/:id/login" component={RLogin} />
      <Route exact path="/:id/thank-you" component={ThankYou} />
      <Route path="/:id" component={Movie} />
    </Switch>
  </Router>
);

export default App;
